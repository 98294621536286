// extracted by mini-css-extract-plugin
export var content = "section-author-module--content--JkLka";
export var descriptionText = "section-author-module--description-text--fM7yc";
export var header = "section-author-module--header--L4XFf";
export var imageBox = "section-author-module--image-box--VVBg-";
export var lightTheme = "section-author-module--light-theme--go+qr";
export var link = "section-author-module--link--CKIiQ";
export var nameBox = "section-author-module--name-box--6vu67";
export var nameText = "section-author-module--name-text--YenbO";
export var ratio = "section-author-module--ratio--w6NYJ";
export var titleText = "section-author-module--title-text--HoHsS";