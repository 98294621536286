import React from 'react';
import { graphql } from 'gatsby';

import { accentColors } from '../config/accent-colors';
import { relations } from '../config/relations';
import { IPost } from '../models/post.model';
import { useT } from '../hooks/use-translation';
import { getPostStructuredData } from '../utils/get-post-structured-data';

import MainLayout from '../layouts/main-layout';
import Hero from '../components/organisms/hero';
import SectionArticle from '../components/organisms/section-article';
import SectionAuthor from '../components/organisms/section-author';

interface IPostPageProps {
    readonly data: {
        post: IPost;
    };
}

const PostPage: React.FC<IPostPageProps> = ({ data }) => {
    const { t } = useT();
    const { post } = data;
    const { media, color, author, tags, title, contentWithTableOfContents } = post;

    const structuredData = getPostStructuredData(post);
    const backgroundColor = accentColors[color] || accentColors.purple;

    return (
        <MainLayout
            showFloatingCircles={true}
            showContactForm={true}
            contactFormProps={{
                titleProps: { children: t('contact.form.title') },
                description: t('post.contact.form.description'),
                inputContentLabel: t('post.contact.form.content.label'),
                buttonProps: {
                    kind: 'normalDark',
                    color: 'yellow',
                },
            }}
            structuredData={structuredData}
        >
            <Hero
                titleProps={{ Tag: 'h1', children: title }}
                circleDisplay="image"
                imageProps={{ media }}
                imageMobileProps={{
                    media,
                    relation: relations.mainImageMobile,
                }}
                style={{ background: backgroundColor }}
                author={author}
                tags={tags}
            />
            <SectionArticle>{contentWithTableOfContents}</SectionArticle>
            {author && <SectionAuthor author={author} theme={color} />}
        </MainLayout>
    );
};

export const query = graphql`
    query PostPage($locale: String!, $postId: Int!) {
        post(locale: { eq: $locale }, postId: { eq: $postId }) {
            ...postFields
        }
    }
`;

export default PostPage;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';
